function slideout_menu(){
  var slideout = new Slideout({
    'panel': document.getElementById('js-so_panel'),
    'menu': document.getElementById('js-so_menu'),
    'duration': 10,
  });
  var nodeList = document.querySelectorAll('#js-so_toggle, #js-so_panel');
  var node = Array.prototype.slice.call(nodeList,0);
  node.forEach(function(elem, index){
    elem.addEventListener('click', function() {
      slideout.toggle();
    });
  });
}

jQuery(function($) {
/*********************************************************************
  common
*********************************************************************/
slideout_menu();
/*********************************************************************
  index
*********************************************************************/
$("#js-so_menu a").on("click", function() {
$(".-main ul").slideToggle();
$("html").removeClass("slideout-open");
});

$('.js-loopslide').infiniteslide({
  speed: 30,
  pauseonhover: false,
  responsive: true,
  });

/*********************************************************************
  sale
*********************************************************************/
$('.js-slide').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000
});
$('.js-slide-thum').slick({
    autoplay:true,
    infinite:true,
    asNavFor: '.js-slide',
    focusOnSelect: true,
    dots:false,
    arrows:true,
    slideToScroll:1,
    slidesToShow:8,
    prevArrow: '<img src="../../assets/img/common/icon_arrow-b02.png" class="slide-arrow prev-arrow">',
    nextArrow: '<img src="../../assets/img/common/icon_arrow-b02.png" class="slide-arrow next-arrow">',
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesToShow: 4,
        infinite:false,
       }
    }]
  });

});
jQuery(window).on('load',function($){
});
jQuery(window).on('scroll',function($){
});
jQuery(window).on('resize',function($){
});
